import { CompanyRole, CompanyState, useConnection } from '@xeptagon_npm/kiuda-carbon-library';
import { Alert, Button, Col, Form, Input, Row, Select, SelectProps } from 'antd';
import { FC, useRef, useState } from 'react';
import { ProgramExchangeCredit } from '../../../Definitions/InterfacesAndType/company.exchangeCredit.enum';

export interface CreditTransferFormProps {
  programmes: ProgramExchangeCredit[];
  onCancel: any;
  actionBtnText: string;
  onTransferSuccess: any;
  subText: string;
  disableToCompany?: boolean;
  isKiudaExTransfer?: boolean;
  toCompanyDefault?: any;
  receiverLabelText: string;
  userCompanyId: number | undefined;
  companyRole: string;
  translator: any;
  ministryLevelPermission?: boolean;
}

export const CreditTransferForm: FC<CreditTransferFormProps> = (props: CreditTransferFormProps) => {
  const {
    programmes,
    onTransferSuccess,
    onCancel,
    actionBtnText,
    subText,
    toCompanyDefault,
    disableToCompany,
    isKiudaExTransfer,
    receiverLabelText,
    userCompanyId,
    companyRole,
    translator: t,
    ministryLevelPermission = false,
  } = props;
  const [popupError, setPopupError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [companyList, setCompanyList] = useState<SelectProps['options']>(
    toCompanyDefault ? [toCompanyDefault] : []
  );
  const [value, setValue] = useState<string>();
  const selectedProgramm = useRef<ProgramExchangeCredit | undefined>(undefined);
  const { get, delete: del, post } = useConnection();
  const [creditTransfer] = Form.useForm();

  const handleChange = (newValue: string) => {
    if (programmes) {
      const matchingProgramme = programmes.find((programm) => programm.programmeId === newValue);
      selectedProgramm.current = matchingProgramme;
      creditTransfer.setFieldsValue({
        programmeName: matchingProgramme?.programmeName,
        transferCreditMaxValue: matchingProgramme?.quantity,
      });
    }
  };

  const transferCredit = async () => {
    try {
      setLoading(true);
      const response: any = await post('national/programme/orgToExTransferRequest', {
        programmeId: selectedProgramm.current?.programmeId,
        fromCompanyId: userCompanyId,
        companyCredit: Number(creditTransfer.getFieldValue('transferCreditValue')),
        comment: creditTransfer.getFieldValue('remark'),
      });

      onTransferSuccess();
    } catch (error: any) {
      setPopupError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const isFormValid = () => {
    if (!selectedProgramm.current?.programmeId) return false;

    if (
      !creditTransfer.getFieldValue('transferCreditValue') ||
      isNaN(creditTransfer.getFieldValue('transferCreditValue'))
    )
      return;

    if (Number(creditTransfer.getFieldValue('transferCreditValue')) <= 0) {
      setPopupError(t('companyDetails:negativeValueMsg'));
      return;
    }

    if (
      selectedProgramm.current.quantity <
      Number(creditTransfer.getFieldValue('transferCreditValue'))
    ) {
      setPopupError(t('companyDetails:insufficientQuantity'));
      return;
    }
    if (creditTransfer.getFieldValue('transferCreditValue').includes('.')) {
      const decimalPlace = creditTransfer.getFieldValue('transferCreditValue').split('.')[1];
      if (Number(decimalPlace) > 0) {
        setPopupError(t('companyDetails:wholeNumber'));
        return;
      }
    }

    return true;
  };

  return (
    <div className="transfer-form">
      <Form
        form={creditTransfer}
        layout="vertical"
        initialValues={{
          programmeName: '',
          transferCreditMaxValue: '',
        }}
        onFinish={() => {
          if (!isFormValid()) return;

          setPopupError(undefined);
          transferCredit();
        }}
        // onValuesChange={(v, allVal) => {
        //   setCurrentSum(
        //     allVal.companyCredit.reduce((a: any, b: any) => (a ? a : 0) + (b ? b : 0), 0)
        //   );
        // }}
        // onFinish={async (d) => {
        //   if (currentSum === 0) {
        //     setPopupError('Total Amount should be greater than 0');
        //     return;
        //   }
        //   setLoading(true);
        //   d.fromCompanyIds = validCompanies.map((e) => Number(e.companyId));
        //   const res = await onFinish(d);
        //   setPopupError(res);
        //   setLoading(false);
        // }}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              className="remarks-label"
              label={t('companyDetails:programmeId')}
              name="programmeId"
              rules={[
                {
                  required: true,
                  message: `${t('companyDetails:programmeId')} ${t('common:isRequired')}`,
                },
              ]}
            >
              <Select
                showSearch
                showArrow={true}
                filterOption={(input: string, option?: { label: string; value: string }) =>
                  (option?.value ?? '').includes(input)
                }
                onChange={handleChange}
                notFoundContent={null}
              >
                {programmes.map((programme) => {
                  return (
                    <Select.Option value={programme.programmeId}>
                      {programme.programmeId}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label={t('companyDetails:programme')} name="programmeName">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              className="remarks-label"
              label={t('companyDetails:transferCredit')}
              name="transferCredit"
            ></Form.Item>
          </Col>
          <Col span={12}>
            <div className="">
              <Row>
                <Col span={11}>
                  <Form.Item
                    name="transferCreditValue"
                    rules={[
                      {
                        required: true,
                        message: `${t('companyDetails:transferCredit')} ${t('common:isRequired')}`,
                      },
                    ]}
                  >
                    <Input type="number" onChange={() => {}} />
                  </Form.Item>
                </Col>
                <Col span={2} className="seperator">
                  /
                </Col>
                <Col span={11}>
                  <Form.Item name="transferCreditMaxValue">
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item
              className="remarks-label"
              label="Remarks"
              name="remark"
              // rules={[
              //   {
              //     required: true,
              //     message: 'Required field',
              //   },
              // ]}
            >
              <Input.TextArea placeholder="" />
            </Form.Item>
          </Col>
        </Row>
        {popupError && (
          <Alert
            className="error"
            message={popupError}
            type="error"
            closable
            onClose={() => {
              setPopupError(undefined);
            }}
            showIcon
          />
        )}
        <Form.Item className="footer">
          <Button htmlType="button" onClick={onCancel}>
            {t('view:cancel')}
          </Button>
          <Button className="mg-left-2" type="primary" htmlType="submit" loading={loading}>
            {actionBtnText}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
