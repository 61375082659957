import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  NdcDetailsComponent,
  useConnection,
  useUserContext,
} from '@xeptagon_npm/kiuda-carbon-library';

const NdcDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['ndc', 'userProfile']);

  return <NdcDetailsComponent t={t}></NdcDetailsComponent>;
};

export default NdcDetails;
