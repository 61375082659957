import { CompanyProfileComponent } from '@xeptagon_npm/kiuda-carbon-library';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAbilityContext } from '../../Casl/Can';
import { CompanyCreditView } from './components/CompanyCreditView';

const CompanyProfile = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'companyProfile', 'companyDetails', 'view']);

  const onNavigateToCompanyManagement = () => {
    navigate('/companyManagement/viewAll');
  };

  const onNavigateToCompanyEdit = (companyDetails: any) => {
    navigate('/companyManagement/updateCompany', { state: { record: companyDetails } });
  };

  return (
    <>
      <CompanyProfileComponent
        t={t}
        useAbilityContext={useAbilityContext}
        useLocation={useLocation}
        onNavigateToCompanyManagement={onNavigateToCompanyManagement}
        onNavigateToCompanyEdit={onNavigateToCompanyEdit}
        regionField
      ></CompanyProfileComponent>
      <div className="content-container company-profile">
        <Row gutter={[16, 0]} className="mx-0">
          <Col md={24} lg={{ span: 16, offset: 8 }}>
            <CompanyCreditView t={t}></CompanyCreditView>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CompanyProfile;
