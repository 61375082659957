import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './Components/Internationalization/i18n';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { avalanche, avalancheFuji } from '@wagmi/core/chains';
import { WagmiConfig, createClient, configureChains } from 'wagmi';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
const { chains, provider } = configureChains(
  [avalancheFuji],
  [
    jsonRpcProvider({
      rpc: (chain) => {
        if (chain.id === avalancheFuji.id) {
          return {
            http: 'https://api.avax-test.network/ext/bc/C/rpc',
            webSocket: 'wss://api.avax-test.network/ext/bc/C/ws',
          };
        }
        return null;
      },
    }),
  ]
);

const client = createClient({
  autoConnect: true,
  provider,
});
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <WagmiConfig client={client}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </WagmiConfig>
  </React.StrictMode>
);
